// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.top-padding{
  padding: 0 2.2em;
	padding-top: 4em;
}
.bottom-padding{
  padding-bottom: 4em;
}
