.frontend-solution{
  min-height: calc(100vh - 300px);
  position: relative;
  margin-top: 5em;
  @media (min-width: 1024px){
    min-height: 90vh;
  }
  @media (min-width: 1600px){
    min-height: calc(100vh - 400px);
  }
  @media (min-height: 650px){
    &>.container{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.section-why{
  .why-list{
    display:flex;
    justify-content: space-around;
    flex-flow:row wrap;
    margin-top:40px;
    .why{
      flex: 1 0 25%;
      margin-right: 75px;
      margin-bottom: 20px;
      .why-logo{
        height:86px;
        width:86px;
        line-height:86px;
        text-align:center;
        background:#fff;
        border-radius:50%;
        box-shadow: 0px 7px 15px rgba(0,0,0,0.1);
      }
      .why-description{
        h4{
          font-size:15px;
          font-weight:500;
        }
      }
    }
  }
}

.section-features{
  overflow:hidden;
  .row-flex{
    margin-top:30px;
    overflow-y: auto;
    .column{
      flex:1 0 40%;
    }
  }
  .feature{
    display:flex;
    align-items:flex-start;
    .number{
      flex:0;
      padding:3px;
      margin-right:20px;
      background:#fff;
      border-radius: 50%;
      border:5px solid #eee;
      span{
        background: #fff;
        display: block;
        border-radius: 50%;
        text-align: center;
        height: 42px;
        width: 42px;
        line-height: 42px;
        font-size: 17px;
        border:2px solid $orange;
      }
    }
    .content{
      padding-right: 50px;
      h5{
        color: #212121;
        font-size: 16px;
        font-weight: 500;
        margin: 22px 0;
      }
    }
  }
}

.section-detail-service{
  .our-works .columns .our-works-content .our-works-list{
    height: auto;
  }
}

.pricing-wrapper{
  color: #fff;
  .price-box{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 15px 0px;
    margin-right: 40px;
    margin-bottom:20px;
    color: #fff;
    .price-logo{
      position:absolute;
      top: 16px;
      left: -28px;
      padding: 6px;
      background:rgba(255, 255, 255, 0.2);
      border-radius:50%;
      span{
        display:block;
        height:42px;
        width:42px;
        line-height:48px;
        text-align:center;
        background:#fff;
        border-radius:50%;
      }
    }
    .price-content{
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      width: 263px;
      height: 263px;
      line-height: 263px;
      background: #15f13a;
      background: linear-gradient(90deg,#ecce36,#15f13a);
      position: relative;
      &.step-row{
        background: none;
        border: 0;
        .gift-sticker{
          width: 263px;
          height: 263px;
        }
        .pulses{
          .pulse{
            left: -185px;
            position: absolute;
            bottom: -55px;
          }
        }
      }
      h2{
        margin-top:0;
        margin-bottom: 15px;
        font-size:44px;
        font-weight: $font-medium;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #2E2F37;
        span{
          font-weight: $font-light;
          font-style: italic;
        }
      }
      p{
        margin-bottom:0;
      }
    }
  }
  .prices-desc{
    display: inline-block;
    vertical-align: middle;
    max-width: 768px;
  }
  .price-notes{
        width: 100%;
    h5{
      font-size: 15px;
      margin-bottom: 5px;
      margin-top: 30px;
    }
  }
}

.you-will-get-wrapper{
  margin-top:75px;
  ul{
    list-style: none;
    padding: 0;
    margin:0;
    li{
      background: url(../images/icons/icon-check.svg) no-repeat 0 12px;
      padding: 10px 0 10px 40px;
      list-style: none;
      margin: 0;
      color: #fff;
      display: inline-block;
      width: 32.333%;
      vertical-align: top;
    }
  }
}

.question-wrapper{
  margin-top:75px;
  .head{
    h2{
      display: inline-block;
      margin-right: 10px;
    }
  }
  ul{
    list-style: none;
    padding:0;
    margin:0;
    li{
      display:flex;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    .number{
      flex:0;
      padding:4px;
      margin-right:20px;
      background:rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      > span{
        display: block;
        background:#fff;
        border-radius: 50%;
        padding:3px;
        span{
          background: #fff;
          display: block;
          border-radius: 50%;
          text-align: center;
          height: 38px;
          width: 38px;
          line-height: 38px;
          font-size: 17px;
          border:2px solid $orange;
        }
      }
    }
    .content{
      color:#fff;
      .question{
        margin:14px 0;
        font-size:16px;
        font-weight:500;
      }
      .answer{
        padding-left:30px;
        display:flex;
        align-items: center;
        &:before{
          content:'';
          background: url(../images/icons/icon-check.svg) no-repeat 0;
          height: 20px;
          width: 20px;
          margin-left: -30px;
          margin-right: 10px;
        }
      }
    }
  }
}

.frontend-solution{
  h2.yellow-text-gradient{
    text-align: center;
  }
  .menu-solution{
    ul{
      padding-top: 10px;
      padding-bottom: 10px;
      list-style: none;
      text-align: center;
      li{
        display: inline-block;
        text-align: center;
        margin: 0 8px;
        &.active{
          a{
            color: $color-yellow;
          }
        }
        &:last-child{
          &:after{
            content: '';
          }
        }
        &:after{
          content: '\f105';
          display: inline-block;
          font-family: 'fontawesome';
          font-size: 13px;
          vertical-align: middle;
          color: $color-yellow;
          position: relative;
          left: 11px;
          top: 0px;
        }
        a{
          color: #b1b1b1;
          text-decoration: none;
          font-size: 13px;
          font-weight: $font-regular;
        }
      }
    }
  }
  roundup{
    display: block;
  }
  .tab-slider{
    height: 500px;
    position: relative;
    background-position: center;
    margin-top: 0px;
    padding-top: 80px;
    overflow: hidden;
    // min-height: 1000px;
    width: 100%;
    display: block;
    .roundup{
      position: relative;
      width: 100%;
      transition: all 1s ease-in-out;
      top: -150px;
      ul{
        position: absolute;
        list-style-type: none;
        padding: 0;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        li{
          position: absolute;
          list-style-type: none;
          width: 0;
          height: 68%;
          margin: 16.35% 50.5%;
          -webkit-transform: rotate(-360deg);
          transition: all 0.8s linear;
          z-index: 400;
          a{
            position: absolute;
            left: 50%;
            bottom: 100%;
            margin-left: 0;
            text-align: center;
            font-size: 1px;
            overflow: visible;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            font-size: 18px;
            border-radius: 100%;
            padding: 10px;
            width: 100px;
            height: 100px;
            line-height: 100%;
            margin-left: -50px;
            .img-wrap{
              display:block;
              position: relative;
              border-radius: 100%;
              background: $color-white;
              height:100%;
              z-index:1;
            }
            .bg-pulse{
              display: block;
              position:absolute;
              top:0;
              left:0;
              bottom:0;
              right:0;
              border-radius:50%;
              z-index:0;
            }
            &.active{
              .bg-pulse{
                background: hsla(37, 96%, 61%, 0.3);
                animation: pulses 1s ease-out infinite;
              }
            }
            img{
              display: block;
              max-width: 50px;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    .track{
      position: absolute;
      border-radius: 100%;
      border: 1px solid rgba(138, 138, 138, 0.16);
      z-index: -1;
      top: 10%;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 0 auto;
      width: 840px;
      height: 840px;
    }
    .item-slider{
      position: absolute;
      top: 30%;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 2;
      width: 30%;
      max-width: 960px;
      min-width: 500px;
      transition: all 0.5s ease-in;
      *{
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        -webkit-animation-fill-mode: none;
        -moz-animation-fill-mode: none;
        -o-animation-fill-mode: none;
        animation-fill-mode: none;
      }
      &.active{
        *{
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }
      .fadeInUp-add{
        transform: transitionY(0) ;
      }
      .item-name{
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: $font-bold;
        color: #2E2F37;
      }
      .item-desc{
        font-size: 13px;
        color: #2E2F37;
        font-weight: $font-regular;
      }
      .item-image{
        img{
          margin: 0 auto;
          padding-top: 0;
          height: 200px;
          margin-top: -25px;
        }
      }
    }
  }
}
.section-detail-service{
  .verticale-txt{
    color: $color-yellow;
    font-weight: $font-medium;
    font-size: 62px;
    display: inline-block;
    vertical-align: top;
        line-height: 75px;
  }
  .features-list{
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 90%;
    height: 100vh;
    .item-list{
      width: 400px;
      position: absolute;
      &.feat1{
        top: 7em;
        left: 50px;
        .bg-white{
          width: 128px;
        }
      }
      &.feat2{
        top: 0;
        left: 15em;
        .bg-white{
          width: 235px;
        }
      }
      &.feat3{
        top: 4em;
        left: 34em;
        .bg-white{
          width: 204px;
        }
      }
      &.feat4{
        top: 13em;
        left: 14em;
        .bg-white{
          width: 235px;
        }
      }
      &.feat5{
        top: 19em;
        left: 34em;
        .bg-white{
          width: 205px;
        }
      }
      &.feat6{
        top: 25em;
        left: 50px;
        .bg-white{
          width: 200px;
        }
      }
      &.feat7{
        top: 26em;
        left: 23em;
        .bg-white{
          width: 170px;
        }
      }
      &.feat8{
        top: 28em;
        left: 40em;
        .bg-white{
          width: 204px;
        }
      }
      &.feat9{
        top: 34em;
        left: 24em;
        .bg-white{
          width: 157px;
        }
      }
      .icon{
        width: 35px;
        height: 35px;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        background: #58a2e9;
        margin: 0 10px;
        text-align: center;
        color: #fff;
        line-height: 35px;
        font-size: 13px;
        &.icon_search{
          &:before{
            content: url(../images/icons/icon-faq-nda.svg);
            display: block;
            margin-top: 9px;
            margin-left: 6px;
          }
        }
      }
      .bg-white{
        border-radius: 4px;
        padding: 20px 18px;
        display: inline-block;
        vertical-align: top;
      }
      h3{
        font-size: 15px;
        line-height: 18px;
        color: #4A4A4A;
        font-weight: $font-medium;
        margin: 0;
      }
      p{
        font-size: 12px;
        color: #212121;
        font-weight: $font-regular;
        line-height: 1.5;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
  .our-works{
    padding-bottom: 0;
    .columns{
      .our-works-desc{
        margin-left: -50px;
        padding-right: 30px;
        width: 36.5%;
      }
    }
  }
  .question-list{
    ul{
      &.quest1{
        li{
          &:first-child{
            max-width: 211px;
          }
          &:nth-last-child(2){
            max-width: 380px;
          }
          &:last-child{
            max-width: 215px;
          }
        }
      }
      &.quest2{
        li{
          &:first-child{
            max-width: 182px;
          }
          &:nth-last-child(2){
            max-width: 228px;
          }
          &:last-child{
            max-width: 396px;
          }
        }
      }
      &.quest3{
        li{
          vertical-align: bottom;
          &:first-child{
            max-width: 208px;
          }
          &:nth-last-child(3){
            max-width: 198px;
          }
          &:nth-last-child(2){
            max-width: 142px;
          }
          &:last-child{
            max-width: 230px;
          }
        }
      }
      li{
        display: inline-block;
        width: 100%;
        background: #fff;
        vertical-align: top;
        border-radius: 4px;
        padding: 12px;
        margin-right: 25px;
        .content{
          color: #212121;
          .question{
            margin-top: 0px;
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 1.5;
          }
          .answer{
            font-size: 12px;
          }
        }
      }
    }
  }
  .have-questions{
    padding-top: 10em;
    padding-bottom: 10em;
  }
}

.form-project{
  .btn-black.with-arrow{
    .progress_circle{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}