.section-how-it-work{
  margin-bottom:0;
  @media(min-height: 950px){
    min-height: auto;
    max-height:950px;
  }
  .section-heading{
    font-size: 62px;
    font-weight: 500;
    line-height: 1em;
    margin:40px 0 15px;
  }
  .process-wrapper{
    padding: 0 2.2em;
  }
  @media (min-width:1400px){
    .process-wrapper{
      max-width: 1250px;
      left:0;
      right:0;
      margin:auto;
    }
  }
  
}

