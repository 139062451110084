.container{
    &.narrow{
        p{
            max-width: 950px;
        }
    }
}
.min-section-static{
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.step-form{
    padding-top: 5em;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 0;
    width: 100%;
    transform: translate(0%, 50%);
    bottom: 50%;
    &.section-3{
        max-height: 100vh;
        overflow-y: auto;
    }
    
    @media(min-height: 1000px){
        height:100vh;
        padding-top:0;
    }
    &.hided{
        transform: translate(0%, 200%);
    }
    &.done{
        transform: translate(0%, -150%);   
    }
}
.step-form[data-steps=step-1]{
  .step-row{
    .scaleSoft {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.step-row{
    display: table;
    &>.col{
        display: table-cell;
        float: none;
        vertical-align: middle;
        @media(min-height: 1000px){
            height:100vh ;
        }
    }
    &:before, &:after{
        display: none;
    }
    .col-3{
        width: 25%;
    }
    .col-4{
        width: 35%;
        padding-left: 20px;
    }
    .col-8{
        width: 65%;
    }
    .col-9{
        width: 75%;
    }
}

.step-btns{
    float: right;
    a{
       font-family: $font-seravek;
        font-weight: normal;
        font-size: 14px;
        color: $color-darker;
        margin-right: 20px;
        cursor: pointer;
    }
    .back{
        &:before{
            content:url(../images/icons/icon-arrow-back.svg);
            margin-right: 5px;
        }
    }
    .retry{
        &:before{
            content:url(../images/icons/icon-retry.svg);
            margin-right: 5px;
            position: relative;
            top: 4px;
        }
    }
}