// dropzone effect
.dropzone {
    .custom_dropzone{
        position: absolute;   
        opacity: 0;
    }
}
.dropzone.in {
    .custom_dropzone{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(242, 242, 242, 0.56);
        opacity: 1;
        z-index: 10;
        .dropcontainer{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            img{
                display: block;
                margin: 0 auto;
                width: 50px;
                margin-bottom: 15px;
            }
        }
    }
}
.dropzone.fade {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    opacity: 1;
}