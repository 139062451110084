.our-workspg{
	> h2.yellow-text-gradient{
		margin-bottom: 10px;
	}
	> p{
		font-size: 15px;
		color: $color-white;
		margin-bottom: 4.5em;
	}
	.our-works-list{
		.our-item{
			width: 245px;
			margin-bottom: 30px;
			position: relative;
			.our-item{
				.titler__title{
					font-size: 20px;
				}
			}
			a{
				width: 100%;
				height: 395px;
				cursor: pointer;
				.tilter__caption{
					padding: 31px;
				}
			}
		}
	}
	.set-goal{
		padding-bottom: 0;
		padding-top: 4em;
		h3{
			margin-bottom: 0;
		}
		p{
			margin-top: 0;
		}
	}
}