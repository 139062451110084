// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button{
	&.button{
		padding: 12px 20px;
		cursor: pointer;
		&:hover, &:focus{
			color: #fff;
			text-decoration: none;
		}
	}
}
.button{
	padding: 10px 12px;
	border-radius: 4px;
	background-color: #2E3038;
	color: #fff;
	font-size: 16px;
	font-family: 'Seravek';
	font-weight: 400;
	text-decoration: none;
  	display: inline-block;
	transition: all .5s ease;
	border:none;
	&:hover, &:focus{
		color: #fff;
		text-decoration: none;
	}
  &.button-rounded{
    padding: 10px 22px;
	  border-radius: 20px;
  }
  &.button-orange{
    color: #2e2f37;
    background:#ffa600;
  }
}

.btn{
	padding: 0 15px;
	border-radius: 4px;
	background-color: #2E3038;
	color: #fff;
	font-size: 16px;
	font-family: 'Seravek';
	font-weight: 500;
	text-decoration: none;
	transition: all .5s ease;
	border: 0;
	height: 44px;
    line-height: 44px;
	cursor: pointer;
	&:hover, &:focus{
		color: #fff;
		text-decoration: none;
	}
	&.with-arrow{
		position: relative;
		border-radius: 25px;
		&:after{
			content:url(../images/icons/icon-arrow.svg);
			line-height: 40px;
			float: right;
			margin-right: -35px;
    		margin-left: 15px;
    		transform: translateX(0);
			transition: transform .5s cubic-bezier(.19,1,.22,1);
		}
		&:before{
			content:'';
			display: block;
			width: 30px;
			height: 3px;
			background: #ffa600;    
			position: absolute;
			right: -15px;
			top: 19px;
			
		}
		&:hover{
			&:after{
				transform: translateX(15px);
				
			}
		}

		&.play{
			&:after{
				animation: arrowplay 1.8s cubic-bezier(.77,0,.175,1) infinite;
			}
		}
	}
}
@keyframes arrowplay{
	0%{
		transform: translateX(0);
	}
	50%{
		transform: translateX(15px);
	}
	100%{
		transform: translateX(0);
	}
}

.btn-yellow{
	background: $color-yellow;
	color: $color-darker;
}
.btn-black{
	background: #2E3038;
	color: $color-white;	
}
a.with-arrow{
    max-width: 190px;
    margin-bottom: 20px;
}
.btn-block{
	  display: block;
}



#wh-widget-send-button #wh-widget-send-button-iframe #wh-widget-send-button-wrapper div.wh-widget-send-button-wrapper div.wh-widget-send-button-wrapper-list>a.wh-widget-button div.wh-widget-button-icon.wh-messenger-bg-whatsapp {
    background-color: #fea601 !important; 
    box-shadow: none !important; 
}

.whatsapp-button{
	position: fixed;
    bottom: 20px;
	right: 20px;
	opacity: 0;
	transition: opacity .3s ease-out;
	z-index: 1200;
	&.fadein{
		opacity: 1;
	}
	a{
		width: 50px;
		height: 50px;
		//background: #fea601;
		background: #4dc247;
		display: block;
		border-radius: 100%;
		line-height: 50px;
		text-align: center;
		.fa{
			font-size: 35px;
			color: #fff;
			line-height: 50px;
		}
	}
}