.section-devstory{
  .story{
    display:flex;
    margin-top:20px;
    overflow: hidden;
    .story-logo{
      flex: 0;
      display: block;
      padding: 10px;
      border-radius: 50%;
      background: rgba(212, 212, 212, 0.2);
      span {
        background: #fff;
        display: block;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        line-height: 42px;
        text-align: center;
      }
    }
    .story-content{
      margin-left:120px;
      padding: 0;
      .column{
        &:first-of-type{
          flex:1 0 20%;
          margin-right:75px;
          position:relative;
          .review-text{
            position:absolute;
            top:2.5%;
            padding-left: 0.6em;
            // width:17%;
          }
        }
        &:last-of-type{
          flex:1 0 70%;
          @media(min-width: 1500px){
            flex:1 0 50%;
          }
        }
      }
      h2{
        margin: 15px 0 20px;
      }
      h5{
        margin-bottom:24px;
      }
      p{
        margin-bottom:30px;
      }
      .stories{
        height: 7500px;
        position: relative;
        padding: 0!important;
        font-size: 1.1rem;
        line-height: 1.6rem;
        img{
          max-width: 100%;
          // @media screen and (min-width:1200px) {
          //   min-height:75vh;
          // }
        }
      }
    }
  }
}

#email-animation {
  /*height: 2150px;*/
  height: 1900px;
  position: relative;
  padding: 0 !important;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.EmailAnimation-wrapper {
  width: 100%;
  height: auto;

  margin: 0 auto;

  display: flex;
  align-items: center;
}

.EmailAnimation {
  opacity:0;
  position: relative;
  perspective: 1000px;

  width: 100%;
  transition:opacity .5s ease-out;
}
.EmailAnimation.rendered{
  opacity:1;
}
.Email {

  border-radius: .5em;
  width: 100%;
  background-color: #FFF;
  border: 1px solid #e5e4e1;
  box-shadow: 0 10px 45px rgba(26,24,20,.12);
  transition: transform .1s ease-in-out;

  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 1rem;
  max-width: 1200px;
  overflow: hidden;
}

.Email-body {
  padding:0;
}

.EmailAnimation-explanation {
  width: 100%;
  height: 60px;

  display: flex;

  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0px;

  opacity: 0;

  transition: opacity 100ms ease-in-out;
  margin: 0 !important;
}

.EmailAnimation-explanation.is-visible {
  opacity: 1;
}

.EmailAnimation-explanationText {
  font-weight: 400;
}

