// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* This stylesheet generated by Transfonter (https://transfonter.org) on June 19, 2017 3:34 AM */

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek.eot');
	src: url('../fonts/Seravek.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek.woff2') format('woff2'),
		url('../fonts/Seravek.woff') format('woff'),
		url('../fonts/Seravek.ttf') format('truetype'),
		url('../fonts/Seravek.svg#Seravek') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-Light.eot');
	src: url('../fonts/Seravek-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-Light.woff2') format('woff2'),
		url('../fonts/Seravek-Light.woff') format('woff'),
		url('../fonts/Seravek-Light.ttf') format('truetype'),
		url('../fonts/Seravek-Light.svg#Seravek-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-Bold.eot');
	src: url('../fonts/Seravek-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-Bold.woff2') format('woff2'),
		url('../fonts/Seravek-Bold.woff') format('woff'),
		url('../fonts/Seravek-Bold.ttf') format('truetype'),
		url('../fonts/Seravek-Bold.svg#Seravek-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-Medium.eot');
	src: url('../fonts/Seravek-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-Medium.woff2') format('woff2'),
		url('../fonts/Seravek-Medium.woff') format('woff'),
		url('../fonts/Seravek-Medium.ttf') format('truetype'),
		url('../fonts/Seravek-Medium.svg#Seravek-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-ExtraLight.eot');
	src: url('../fonts/Seravek-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-ExtraLight.woff2') format('woff2'),
		url('../fonts/Seravek-ExtraLight.woff') format('woff'),
		url('../fonts/Seravek-ExtraLight.ttf') format('truetype'),
		url('../fonts/Seravek-ExtraLight.svg#Seravek-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-Italic.eot');
	src: url('../fonts/Seravek-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-Italic.woff2') format('woff2'),
		url('../fonts/Seravek-Italic.woff') format('woff'),
		url('../fonts/Seravek-Italic.ttf') format('truetype'),
		url('../fonts/Seravek-Italic.svg#Seravek-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-ExtraLightItalic.eot');
	src: url('../fonts/Seravek-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-ExtraLightItalic.woff2') format('woff2'),
		url('../fonts/Seravek-ExtraLightItalic.woff') format('woff'),
		url('../fonts/Seravek-ExtraLightItalic.ttf') format('truetype'),
		url('../fonts/Seravek-ExtraLightItalic.svg#Seravek-ExtraLightItalic') format('svg');
	font-weight: 200;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-MediumItalic.eot');
	src: url('../fonts/Seravek-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-MediumItalic.woff2') format('woff2'),
		url('../fonts/Seravek-MediumItalic.woff') format('woff'),
		url('../fonts/Seravek-MediumItalic.ttf') format('truetype'),
		url('../fonts/Seravek-MediumItalic.svg#Seravek-MediumItalic') format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-BoldItalic.eot');
	src: url('../fonts/Seravek-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-BoldItalic.woff2') format('woff2'),
		url('../fonts/Seravek-BoldItalic.woff') format('woff'),
		url('../fonts/Seravek-BoldItalic.ttf') format('truetype'),
		url('../fonts/Seravek-BoldItalic.svg#Seravek-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: 'Seravek';
	src: url('../fonts/Seravek-LightItalic.eot');
	src: url('../fonts/Seravek-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Seravek-LightItalic.woff2') format('woff2'),
		url('../fonts/Seravek-LightItalic.woff') format('woff'),
		url('../fonts/Seravek-LightItalic.ttf') format('truetype'),
		url('../fonts/Seravek-LightItalic.svg#Seravek-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Semibold.woff2') format('woff2'),
        url('../fonts/Graphik-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
