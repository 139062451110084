.cookies_wrap{
	position: fixed;
	bottom: 50px;
	z-index: 10;
	margin: auto;
	left: 0;
	right: 0;
	&.sink{
		z-index:-10;
	}
}

.cookies_popup{
	// position: fixed;
	// bottom: 120px;
	width: auto;
	background-color: #fff;
	padding: 12px;
	z-index: 10;
	left: 6.2em;
	border-radius: 4px;
	border-left: 2px solid #ffa600;
	box-shadow: 0 2px 9px rgba(0, 0, 0, .15);
	transition: all .3s ease-in-out;
	opacity: 0;
	max-width: 550px;
	transform: translateY(-100%);
	// &.animated{
	// 	-webkit-animation-duration: .4s;
	// 	animation-duration: .4s;
	// 	-webkit-animation-fill-mode: forwards;
	// 	animation-fill-mode: forwards;
	// 	-webkit-animation-name: a;
	// 	animation-name: a;
	// 	-webkit-animation-timing-function: cubic-bezier(.6,0,.4,1);
	// 	animation-timing-function: cubic-bezier(.6,0,.4,1);
	// }
	&.addopacity{
		opacity: 1;
		transform: translateY(0%);
	}
	&.addopacity.closed{
		opacity: 0;
		transform: translateY(100%);
	}
	
	p{
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		margin-right: 10px;
	}
	button{
		display: inline-block;
		vertical-align: middle;
		color: $color-white;
		background-color: $orange;
		border: none;
		border-radius: 4px;
		font-size: 14px;
		padding: 7px 12px;
		cursor: pointer;
	}
}