
.invisible {
  visibility: hidden;
  opacity: 0;
  &.inview{
    visibility: visible;
    opacity: 1;
  }
}

.animated:not(.people){
   transition: opacity 1s cubic-bezier(.25,.46,.45,.94),transform 1s cubic-bezier(.25,.46,.45,.94);
   &.animated-head{
      transition: all 1.2s cubic-bezier(0.22, 0.61, 0.36, 1);
      // cubic-bezier(0.43, 0.29, 0.65, 1.18)
      // all .7s cubic-bezier(0, 0, 0.2, 1)

       &.delay-0{transition-delay:0ms !important}
       &.delay-50{transition-delay:60ms}
       &.delay-100{transition-delay:.12s !important}
       &.delay-150{transition-delay:.18s}
       &.delay-200{transition-delay:.24s}
       &.delay-250{transition-delay:.3s}
       &.delay-300{transition-delay: .36s}
       &.delay-350{transition-delay:.42s !important}
       &.delay-400{transition-delay:.48s}
       &.delay-450{transition-delay:.54s}
       &.delay-500{transition-delay:.6s}
       &.delay-550{transition-delay:.66s !important}
       &.delay-600{transition-delay:.72s}
       &.delay-650{transition-delay:.78s}
       &.delay-700{transition-delay:.84s}
       &.delay-750{transition-delay:.9s !important}
       &.delay-800{transition-delay:.96s}
       &.delay-850{transition-delay:1.02s}
       &.delay-900{transition-delay:1.08s}
       &.delay-950{transition-delay:1.14s !important}
       &.delay-1000{transition-delay:1.2s}
       &.delay-1050{transition-delay:1.26s}
       &.delay-1100{transition-delay:1.32s}
       &.delay-1150{transition-delay:1.38s !important}
       &.delay-1200{transition-delay:1.44s}
       &.delay-1250{transition-delay:1.5s}
       &.delay-1300{transition-delay:1.56s}
       &.delay-1350{transition-delay:1.62s !important}
       &.delay-1400{transition-delay:1.68s}
       &.delay-1450{transition-delay:1.74s}
       &.delay-1500{transition-delay:1.8s}
       &.delay-1550{transition-delay:1.86s !important}&.delay-1600{transition-delay:1.92s}&.delay-1650{transition-delay:1.98s}&.delay-1700{transition-delay:2.04s}&.delay-1750{transition-delay:2.1s}&.delay-1800{transition-delay:2.16s}&.delay-1850{transition-delay:2.22s}&.delay-1900{transition-delay:2.28s}&.delay-1950{transition-delay:2.34s}&.delay-2000{transition-delay:2.4s}&.delay-2050{transition-delay:2.46s}&.delay-2100{transition-delay:2.52s}&.delay-2150{transition-delay:2.58s}&.delay-2200{transition-delay:2.64s}&.delay-2250{transition-delay:2.7s}&.delay-2300{transition-delay:2.76s}&.delay-2350{transition-delay:2.82s}&.delay-2400{transition-delay:2.88s}&.delay-2450{transition-delay:2.94s}&.delay-2500{transition-delay:3s}&.delay-2550{transition-delay:3.06s}&.delay-2600{transition-delay:3.12s}&.delay-2650{transition-delay:3.18s}&.delay-2700{transition-delay:3.24s}&.delay-2750{transition-delay:3.3s}&.delay-2800{transition-delay:3.36s}&.delay-2850{transition-delay:3.42s}&.delay-2900{transition-delay:3.48s}&.delay-2950{transition-delay:3.54s}&.delay-3000{transition-delay:3.6s}
   }
   &.delay-0{transition-delay:0ms !important}
   &.delay-50{transition-delay:60ms}
   &.delay-100{transition-delay:.12s !important}
   &.delay-150{transition-delay:.18s}
   &.delay-200{transition-delay:.24s}
   &.delay-250{transition-delay:.3s}
   &.delay-300{transition-delay: .36s}
   &.delay-350{transition-delay:.42s !important}
   &.delay-400{transition-delay:.48s}
   &.delay-450{transition-delay:.54s}
   &.delay-500{transition-delay:.6s}
   &.delay-550{transition-delay:.66s !important}
   &.delay-600{transition-delay:.72s}
   &.delay-650{transition-delay:.78s}
   &.delay-700{transition-delay:.84s}
   &.delay-750{transition-delay:.9s !important}
   &.delay-800{transition-delay:.96s}
   &.delay-850{transition-delay:1.02s}
   &.delay-900{transition-delay:1.08s}
   &.delay-950{transition-delay:1.14s !important}
   &.delay-1000{transition-delay:1.2s}
   &.delay-1050{transition-delay:1.26s}
   &.delay-1100{transition-delay:1.32s}
   &.delay-1150{transition-delay:1.38s !important}
   &.delay-1200{transition-delay:1.44s}
   &.delay-1250{transition-delay:1.5s}
   &.delay-1300{transition-delay:1.56s}
   &.delay-1350{transition-delay:1.62s !important}
   &.delay-1400{transition-delay:1.68s}
   &.delay-1450{transition-delay:1.74s}
   &.delay-1500{transition-delay:1.8s}
   &.delay-1550{transition-delay:1.86s !important}&.delay-1600{transition-delay:1.92s}&.delay-1650{transition-delay:1.98s}&.delay-1700{transition-delay:2.04s}&.delay-1750{transition-delay:2.1s}&.delay-1800{transition-delay:2.16s}&.delay-1850{transition-delay:2.22s}&.delay-1900{transition-delay:2.28s}&.delay-1950{transition-delay:2.34s}&.delay-2000{transition-delay:2.4s}&.delay-2050{transition-delay:2.46s}&.delay-2100{transition-delay:2.52s}&.delay-2150{transition-delay:2.58s}&.delay-2200{transition-delay:2.64s}&.delay-2250{transition-delay:2.7s}&.delay-2300{transition-delay:2.76s}&.delay-2350{transition-delay:2.82s}&.delay-2400{transition-delay:2.88s}&.delay-2450{transition-delay:2.94s}&.delay-2500{transition-delay:3s}&.delay-2550{transition-delay:3.06s}&.delay-2600{transition-delay:3.12s}&.delay-2650{transition-delay:3.18s}&.delay-2700{transition-delay:3.24s}&.delay-2750{transition-delay:3.3s}&.delay-2800{transition-delay:3.36s}&.delay-2850{transition-delay:3.42s}&.delay-2900{transition-delay:3.48s}&.delay-2950{transition-delay:3.54s}&.delay-3000{transition-delay:3.6s}

}

.slideSoftUp{
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;
  &.inview{
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.slideUp{
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  &.inview{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideSoftLeft{
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
  &.inview{
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideSoftRight{
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
  opacity: 0;
  &.inview{
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.scaleSoft{
  transform: scale(1.3);
  opacity: 0;
  transition: opacity 1s cubic-bezier(.25,.46,.45,.94),transform .3s cubic-bezier(.25,.46,.45,.94);
  &.inview{
    opacity: 1;
    transform: scale(1);
  }
}

.fadeInSoft{
  opacity: 0;
  transition: opacity 1s cubic-bezier(.25,.46,.45,.94),transform 1s cubic-bezier(.25,.46,.45,.94);
  &.inview{
    opacity: 1;
  }
}

.fadeInSuperSoft{
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1s cubic-bezier(.25,.46,.45,.94),transform 1s cubic-bezier(.25,.46,.45,.94);
  &.inview{
    opacity: 1;
    transform: translateY(0);
  }
}


.slideDownBounce{
  transform: translateY(-1000%);
  &.inview{
    transform: translateY(0);
  }
}
.slideUpBounce{
  transform: translateY(1000%);
  &.inview{
    transform: translateY(0);
  } 
}
.slideLeftBounce{
  transform: translateX(1000%);
  &.inview{
    transform: translateX(0);
  } 
}
.fadeOutLeft2{
  opacity: 1;
  transform: translateX(0);  
  &.inview{
    opacity: 0;
    transition: opacity .7s cubic-bezier(.25,.46,.45,.94),transform 1s cubic-bezier(.25,.46,.45,.94);
    transform: translateX(-100%);    
  } 
} 
.slideRightBounce{
  transform: translateX(0);
  &.inview{
    transform: translateX(-100%);
  }
}
.slideInLeftBounce{
  transform: translateX(100%);
  opacity: 0;
  &.inview{
    transform: translateX(0%);
    opacity: 1;
  }
}