.section-sitemap{
  min-height: 100vh;
  padding-top: 60px;
  padding: 4em 8.4em 2em 9.2em;
  .section-heading{
    margin-bottom:30px;
  }
  ul{
    list-style: none;
    padding:0;
    margin:0;
    &.siteall{
      ul{
        margin-bottom: 5px;
        min-height: 50px;
        >li{
          box-shadow: none;
          &:hover{
            a{
              color: $color-yellow;
            }
            &>ul{
              background: transparent;
              >li{
                &:hover{
                  border: 0;
                  background: transparent;
                  
                }
              }
            }
          }
          
        }
      }
      > li:not(.origin){
        > a{
          display: block;
          padding: 10px 18px 5px;
        }
        >.icon{
          margin-left: 15px;
          margin-bottom: 10px;
        }
      }
      
    }
    > li {
      margin-bottom:19px;
      background: #fff;
      margin-right: 25px;
      border-radius: 4px;
      box-shadow: 0px 4px 7px rgba(202, 202, 202, 0.28);
      border: thin solid #fff;
      transition: all .3s ease-out;
      &:hover{
        box-shadow: none;
        border: thin solid #e9e9e9;
        background: transparent;
      }
      > a{
        color: #2e2f37;
        font-size: 15px;
        font-weight: 500;
        padding: 12px 18px;
        display: block;
        &:hover{
          // text-decoration: underline;
        }
      }
      > ul{
        li{
          margin-bottom: 0;
          padding: 0;
          background: transparent;
          border-color: transparent !important;
          a{
            font-size:14px;
            font-weight: 400;
            padding: 0 18px;
          }
        }
      }
    }
    .icon{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      // background: #c73299;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      text-align: center;
      // padding: 6px;
      &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
      }
      &.icon_sitemaps{
        &:before{
          content: '';
          width: 100%;
          height: 100%;
          background-image: url(../images/icons/icon-faq-dialogue.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          display: block;
        }
      }
      &.icon_siteHome{
        &:before{
           background-image: url(../images/icons/sitemaps/home.svg);
        }
      }
      &.icon_siteOurworks{
        &:before{
           background-image: url(../images/icons/sitemaps/ourworks.svg);
        }
      }
      &.icon_siteAgency{
        &:before{
           background-image: url(../images/icons/sitemaps/foragencies.svg);
        } 
      }
      &.icon_siteQuotes{
        &:before{
           background-image: url(../images/icons/sitemaps/getafreequotes.svg);
        } 
      }
      &.icon_siteHowWorks{
        &:before{
           background-image: url(../images/icons/sitemaps/howitworks.svg);
        }  
      }
      &.icon_siteDevstory{
        &:before{
           background-image: url(../images/icons/sitemaps/successstory.svg);
        }  
      }
      &.icon_sitePricing{
        &:before{
           background-image: url(../images/icons/sitemaps/pricing-service.svg);
        }  
      }
      &.icon_siteFaq{
        &:before{
           background-image: url(../images/icons/sitemaps/faq.svg);
        }  
      }
      &.icon_sitePolicies{
        &:before{
           background-image: url(../images/icons/sitemaps/ourpolicy.svg);
        }  
      }
      &.icon_sitemContact{
        &:before{
           background-image: url(../images/icons/sitemaps/contact.svg);
        }  
      }
      &.icon_siteServices{
        &:before{
           background-image: url(../images/icons/sitemaps/services.svg);
        } 
      }
      &.icon_siteOrder{
        &:before{
           background-image: url(../images/icons/sitemaps/ordernow.svg);
        } 
      }
    }
  }
}
