.get-quote{
	max-width: 980px;
	padding-bottom: 14em;
	.number-phone{
		span{
			width: 85px;
			display: inline-block;
			margin-right: 30px;
			input{
				width: 100%;
			}
		}
		.numberphone{
			display: inline-block;
			width: calc(100% - 120px);
		}
	}
	.action{
		margin-top: 15px;
	}
}