.contactus{
	padding-top: 50px;
	.contactus-located{
		display: inline-block;
		vertical-align: top;
		h4, h5{
			margin: 0;
			font-size: 16px;
			line-height: normal;
			color: $color-white;
		}
		h4{
			font-weight: 500;
			margin-bottom: 5px;
		}
		h5{
			font-weight: 400;	
			font-style: italic;
		}
	}
	.free-quotes{
		display: inline-block;
		vertical-align: top;
		margin-left: 7.5em;
		.button-quotes{
			border-radius: 4px;
			max-width: none;
			// font-weight: 400;
			// padding: 10px 20px;
		}
	}
}
.contactus-detail{
	padding: 20px;
	background: #fff;
	border-left: 3px solid #ffa600;
	border-radius: 4px;
	margin-top: 10em;
	width: 100vw;
	left: 21px;
	// box-shadow: 0 10px 15px hsla(0, 0%, 76%, 0.56);
	.contact-max-width{
		display: flex;
		justify-content: flex-start;
		max-width: 1180px;
	}
	.contactus-detail-item{
		// display: inline-block;
		vertical-align: top;
		margin-right: 10px;
		width: 31.333%;
		border-right: 1px solid #d6d6d6;
		padding-top: 25px;
		padding-left: 25px;
		padding-bottom: 20px;
		&:last-child{
			border-right: none;
		}
		h3{
			font-size: 16px;
			line-height: normal;
			margin: 0;
			font-weight: 500;
			margin-bottom: 10px;
			color: #2E2F37;
		}
		p{
			font-weight: 400;
			color: #2E2F37;
			margin-bottom: 6px;
			line-height: 1.6;
			font-size: 15px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}