.row-flex{
  display:flex;
  .column{
    flex:1 0 0;
  }
  &.v-center{
    align-items: center;
  }
  &.h-center{
    justify-content: center;
  }
}
