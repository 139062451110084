.detail{
	.project-steps > .container {
		padding: 0 2.8em 0 2em;
	}
	.scope-project{
		text-align: center;
		padding-top: 34px;
    	padding-bottom: 35px;
		position: relative;
		h3{
			margin: 0;
			margin-bottom: 10px;
			font-size: 14px;
			color: #212121;
			text-transform: uppercase;
			font-weight: $font-regular;
			line-height: 1;
		}
		ul{
			list-style: none;
			margin: 0;
			padding-left: 15px;
			li{
				display: inline-block;
				border: 1px solid rgba(0, 0, 0, 0.2);
				padding: 6px 12px;
				border-radius: 4px;
				margin-right: 15px;
				font-size: 15px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.backdrop-overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
	}
	.project-detail{
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 100vh;
		position: relative;
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		.detail-hero{
			height: calc(100vh - 225px);
			// position: relative;
		}
		.web-development{
			color: $color-white;
			font-size: 15px;
			font-weight: $font-regular;
			margin-top: 25px;
			b{
				font-weight: $font-medium;
			}
		}
		.center-logo{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		.action-button-pages{
			position: absolute;
			bottom: 25px;
			width: calc(100% - 5em);
			a{
				padding: 8px 16px 8px 43px;
				font-size: 15px;
				position: relative;
				cursor: pointer;
				width: 150px;
				span{
					z-index: 2;
					position: relative;
				}
				&.visit-pages{
					float: left;
					background: transparent;
					color: $color-white;
					border: 2px solid $color-yellow;
					position: relative;
					overflow: hidden;
					&:before{
						content:url(../images/icons/icon-visitproject.svg);
						position: absolute;
						left: 17px;
						top: 9px;
						z-index: 2;
					}
					&:after{
						position: absolute;
						content: "";
						width: 130%;
						height: 100%;
						background-color: $color-yellow;
						-webkit-transform: skewX(45deg) translateX(0);
						transform: skewX(45deg) translateX(0);
						transition: -webkit-transform .3s ease;
						transition: transform .3s ease;
						transition: transform .3s ease,-webkit-transform .3s ease;
						z-index: 1;
						will-change: transform;
						top: 0;
						left: -25px;
					}
					&:hover{
						color:$color-yellow; 
						&:after{
							transform: skewX(45deg) translateX(-110%);
						}
						&:before{
							content:url(../images/icons/icon-visitproject-orange.svg);
						}
					}
				}
				&.next-project{
					float: right;
					background: transparent;
					color: $color-white;
					border: 2px solid $color-white;
					position: relative;
					overflow: hidden;
					padding-left: 41px;
					&:before{
						content:url(../images/icons/icon-nextproject.svg);
						position: absolute;
						left: 13px;
						top: 10px;
						z-index: 2;
					}
					&:after{
						position: absolute;
						content: "";
						width: 130%;
						height: 100%;
						background-color:$color-white;
						transform: skewX(45deg) translateX(110%);
						transition: -webkit-transform .3s ease;
						transition: transform .3s ease;
						transition: transform .3s ease,-webkit-transform .3s ease;
						z-index: 1;
						will-change: transform;
						top: 0;
						left: -25px;
					}
					&:hover{
						color:$color-darker; 
						&:after{
							transform: skewX(45deg) translateX(5px);
						}
						&:before{
							content:url(../images/icons/icon-nextproject-black.svg);
						}
					}
					&.prev{
						margin-right: 15px;
						padding-left: 11px;
    					padding-right: 26px;
						&:after{
							width:150%;
						}
						&:before{
							transform:rotate(180deg);
							top: 6px;
							left: auto;
							right: 13px;
						}
					}
				}
			}
		}
		.img-bg{
			float: left;
		}
		.project-desc{
			float: left;
			max-width: 70%;
			padding-left: 5em;
			h4{
				font-size: 15px;
				font-family: $font-seravek;
				font-weight: $font-medium;
				margin: 0;
				line-height: 1;
				&.title{
					font-size: 16px;
				}
			}
			h3{
				font-size: 21px;
				color: #000;
				line-height: 1;
				margin-bottom: 2em;
			}
			.scope-works{
				margin-bottom: 5em;
				h4{
					margin-bottom: 10px;
				}
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
					font-size: 13px;
					font-weight: $font-regular;
				}
			}
			.the-client{
				h4{
					margin-bottom: 10px;
				}
				p{
					font-size: 13px;
					font-weight: $font-regular;	
				}
				.bg-logos{
					width: 550px;
					height: 161px;
					border-radius: 4px;
					background: #fff;
					position: relative;
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	.project-steps{
		padding: 4em 0;
		h4{
			font-size: 16px;
			font-family: $font-seravek;
			font-weight: $font-medium;
			margin: 0;
			line-height: 1;
			margin-bottom: 20px;
		}
		.project-goals{
			.items{
				.number{
					width: 24px;
					height: 24px;
					border-radius: 50%;
					background: $orange;
					font-size: 13px;
					text-align: center;
					color: #fff;
					line-height: 24px;
					display: inline-block;
					vertical-align: top;
					margin-right: 15px;
				}
				.desc{
					max-width: 90%;
					display: inline-block;	
					vertical-align: top;
					h4.title{
						margin-bottom: 10px;
						font-size: 13px;
					}
					p{
						font-size: 15px;
						font-family: $font-seravek;
						font-weight: $font-regular;
						color: $color-white;
						padding-top: 30px;
					}
				}
			}
		}
		.steps-detail{
			h3{
				font-size: 18px;
				font-family: $font-seravek;
				font-weight: $font-medium;
				margin: 0;
				line-height: 1;
				margin-bottom: 10px;
			}
			.category{
				color: #1B1B26;
				font-size: 28px;
				font-weight: $font-x-light;
				margin-bottom: 5px;
			}
			p{
				font-size: 15px;
				color: $color-white;
				font-weight: $font-regular;
			}
		}
		.img-detail-step{
			border-radius: 4px;
			overflow: hidden;
			position: relative;
			img{
				width: 100%;
			}
		}
	}
	.result{
		.items{
			margin-bottom: 15px;
			h4{
				margin-bottom: 10px;
			}
		}
	}
	.columns{
		display: flex;
		padding-top: 60px;
		.column{
			width: 65%;
			.steps-detail{
				h2{
					margin-top:0;
				}
				p{
					strong{
						display: block;
						margin-top:10px;
						font-weight: $font-medium;
						&:first-child{
							margin-top:0;
						}
					}
					a{
						color: $color-yellow-light;
						font-weight:normal;
						&:hover{
							color: $color-yellow;
						}
					}
				}
			}
			&:first-child{
				width: 45%;
			}
		}
	}
}