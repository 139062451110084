.pagination-container{
	position: relative;
	z-index:7;
	&.fadein{
		.pagination.one-page{
			opacity: 1;
		}
	}
}
.pagination.one-page{
	list-style: none;
	position: fixed;
    z-index: 8;
	top: 0;
	left: 1.5em;
	margin: 0;
	padding: 0;
	height: 100%;
	display: block !important;
	transition: all .5s ease-in-out;
	opacity: 0;
	ul{
		padding: 0;
		position: absolute;
		top: 53%;
		transform: translate(0%, -50%);
	}
	li{
		display: block;
		&.active{
			a{
				background: $orange !important;
			}
		}
		&.next, &.prev{
			a{
				text-align: center;
				color: $color-black;
				border-radius: 0;
				background: transparent;
				width: auto;
				height: auto;
			}
		}
		&.next{
			margin-bottom: 9em;
			margin-top: 7em;
			.fa-long-arrow-up{
				&:before{
					content:url(../images/icons/icon-arrow-up.svg);
					display: block;
					width: 10px;
					height: 20px;
				}
			}
			
		}
		&.prev{
			margin-top: 11em;
			position: absolute;
			bottom: 2em;
			.fa-long-arrow-down{
				&:before{
					content:url(../images/icons/icon-arrow-down.svg);
					display: block;
					width: 10px;
					height: 20px;
				}
			}
		}
		a{
			text-decoration: none;
			margin: 13px 10px;
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: rgba(0,0,0,.12);
		}
		
	}
	&.light{
		li{
			&.next{
				&:hover{
					animation: arrowup 1s infinite ease-in-out 0s;
				}
				a{
					background: none;
				}
				.fa-long-arrow-up{
					&:before{
						content:url(../images/icons/icon-arrow-up-white.svg);
					}
				}
			}
			&.prev{
				&:hover{
					animation: arrowdown 1s infinite ease-in-out 0s;
				}
				a{
					background: none;
				}
				.fa-long-arrow-down{
					&:before{
						content:url(../images/icons/icon-arrow-down-white.svg);
					}
				}
			}
		}
	}
}

@keyframes arrowup{
	from {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	65% {
		-webkit-transform: translateY(-11px);
		transform: translateY(-11px);
	}
	to {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@keyframes arrowdown{
	from {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	65% {
		-webkit-transform: translateY(11px);
		transform: translateY(11px);
	}
	to {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}