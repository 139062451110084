/**
 * Dialogs
 * --------------------------------------------------
 * A custom style for dialogs.
 */

 .dialog-wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 16000161;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    display: table;
    height: 100vh;
    .dialog-overlay{
        background: transparent;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        transform: translateX(0%);
        transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
        transition-delay:1s;
        // -webkit-transition-duration: 0.4s;
	    // transition-duration: 0.4s;
        // opacity: 0;
        // visibility: hidden;
        // -webkit-transition: opacity 0.3s;
        // transition: opacity 0.3s;
        // -webkit-backface-visibility: hidden;
        &.inload{
            transform: translateX(-100%);
        }
    }
    .dialog-fader{
        background: #efefef;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        opacity:1;
        transition: opacity 1s cubic-bezier(.25,.46,.45,.94);
        transition-delay:1s;
        visibility:hidden;
        z-index:-1;
        &.rise{
            visibility:visible;
        }
        &.inload{
            opacity:0;
        }
        &.sink{
            visibility:hidden;
        }
    }
    .dialog-overflow{
        max-width: 800px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        padding: 25px 0;
        &.hideleftright{
            &:before, &:after{
                display: none;
            }
        }
        &:before{
            content: "";
            top: 0;
            left: 0;
            right: auto;
            width: 20px;
            height: 100%;
            z-index: 2;
            background: #f5f5f5;
            -webkit-mask-image: linear-gradient(-90deg,transparent 30%, #f5f5f5);
            mask-image: linear-gradient(-90deg,transparent 30%, #f5f5f5);
            width: 60px;
            position: absolute;
        }
        &:after{
            content: "";
            top: 0;
            left: auto;
            right: 0;
            width: 20px;
            height: 100%;
            z-index: 2;
            background: #f5f5f5;
            -webkit-mask-image: linear-gradient(90deg,transparent 30%, #f5f5f5);
            mask-image: linear-gradient(90deg,transparent 30%, #f5f5f5);
            width: 60px;
            position: absolute;
        }
    }
    .dialog-centerer{
        display: table-cell;
        vertical-align: middle;
    }
    .dialog-box{
        border-radius: 4px;
        position: relative;
        // opacity: 0;
        max-width: 650px;
        // min-width: 320px;
        margin: auto;
        background-color: $color-white;
		padding:40px;
        // box-shadow: 0 5px 40px 0px rgba(80, 80, 80, 0.22);
        box-shadow: 3px 5px 21px rgba(187, 187, 187, 0.2);
        &.slideInLeftBounce.inview{
            &.invert{
                transform: translateX(-500%) !important;
            }
        }
        .action-close{
            background: #fafafa;
            margin-bottom: -20px;
            margin-right: -40px;
            margin-left: -40px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            transition: background .3s ease;
            &:hover{
                background: #f7f7f7;
            }
            a{
                font-size: 13px;
                text-transform: uppercase;
                letter-spacing: .5px;
                font-weight: 400;
                color: #313131;
                display: block;
                padding: 15px 40px;
                cursor: pointer;
            }
        }
        .dialog-title{
            font-size:17px;
            color: rgba(0,0,0,.87);
            text-align: center;
            margin: 0;
            margin-top: 10px;
        }
        p{
            font-size: 13px;
        }
        .close{
            width: 40px;
            height: 40px;
            background: $color-darker;
            color: $color-white;
            font-size:20px;
            line-height: 40px;
            text-align: center;
            display: block;
            border-radius: 100%;
            position: absolute;
            top: -25px;
            left: -25px;
            padding: 2px 0;
            cursor: pointer;
            .fa:before{
                width: 14px;
                height: 14px;
            }
        }
    }
    &.dialog-open{
            opacity: 1;
            visibility: visible;
        .dialog-overlay{
            // background: rgba(231,231,231,.92);
            background: #f5f5f5;
        }
        .dialog-box{
            // -webkit-animation-duration: 0.4s;
            // animation-duration: 0.4s;
            // -webkit-animation-fill-mode: forwards;
            // animation-fill-mode: forwards;
            // -webkit-animation-name: anim-open;
            // animation-name: anim-open;
            // -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
            // animation-timing-function: cubic-bezier(0.6,0,0.4,1);
        }
    }
    &.dialog-close{
        opacity: 0;
        visibility: hidden;
        transition: opacity 5s ease;
        // transition-delay: 2s;

        .dialog-overlay{
            opacity: 0;
            visibility: hidden;
            transition: opacity 5s ease;
            transition-delay: 2s;
        }
        .dialog-box{
            -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-name: anim-close;
	        animation-name: anim-close;
            -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
            animation-timing-function: cubic-bezier(0.6,0,0.4,1);
        }
    }
    .dialog-btn{
        display: block;
        border: 0;
        background: #fff;
        color: $color-dark;
        width:100%;
        font-size: 18px;
        padding: 15px;
    }

    &.popup-gift{
        .dialog-centerer{
            opacity: 0;
            transition: opacity .5s ease;
            transition-delay: 2s;
        }
        &.dialog-open{
            .dialog-centerer{
                opacity: 1;
                z-index: 1;
                position: relative;
            }
        }
    }

    &.logo-only{
        .dialog-centerer{
            .dialog-overflow{
                max-width: 270px;
                .dialog-box.logo{
                    text-align: center;
                    background: none;
                    border: 0;
                    box-shadow: none;
                    .close{
                        display: none;
                    }
                }
                
                
            }
        }
    }
 }


 @-webkit-keyframes anim-open {
	0% { opacity: 0; /*-webkit-transform: scale3d(0, 0, 1);*/ }
	100% { opacity: 1; /*-webkit-transform: scale3d(1, 1, 1);*/ }
}

@keyframes anim-open {
	0% { opacity: 0; /*-webkit-transform: scale3d(0, 0, 1); transform: scale3d(0, 0, 1);*/ }
	100% { opacity: 1; /*-webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1);*/ }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; /*-webki56t-transform: scale3d(0.5, 0.5, 1);*/ }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; /*-webkit-transform: scale3d(0.5, 0.5, 1); transform: scale3d(0.5, 0.5, 1);*/ }
}

body{
    overflow: auto;
}
body.dialog-open{
    overflow: hidden;
}