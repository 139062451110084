.error-page{
    max-height:100vh;
    overflow:hidden;
}
.error-wrapper{
    display: table;
    width: 100%;
    margin-top: -98px;
    .error-text{
        display: table-cell;
        vertical-align: middle;
        color: $color-white;
        text-align: center;
        height: 100vh;
        
        h3{
            font-size: 24px;
            font-weight: $font-medium;
            line-height:32px;
            margin:0;
        }
        h1{
            font-size: 144px;
            font-weight: $font-medium;
            line-height: 150px;
            margin:0;
        }
        h5{
            font-size: 24px;
            font-weight: $font-light;
            line-height: 28px;
            margin:0;
        }
    }
}