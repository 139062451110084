.section{
  padding: 40px 40px 40px 105px;
  .section-heading{
    font-size: 62px;
    font-weight: 500;
    line-height: 1em;
    margin:40px 0 0;
  }
  .section-description{
    font-size: 15px;
    font-weight: 400;
    margin-top:0;
  }
  &.section-normal{
    margin-bottom:0;
  }
  &.section-kodeku{
    padding: 40px;
  }
}
.home{
  .section{
    &.section-kodeku{
      padding-top: 0;
      margin-top: -100px;
    }
  }
}

.bg-color-gradient{
  // background: #5c76b7;
  // background: linear-gradient(-90deg,#5c76b7,#39d4a9);
  min-height: 100vh;
}
.bg-color-gradientTop{
  background: #5c76b7;
  background: linear-gradient(180deg,#5c76b7,#39d4a9);
  min-height: 100vh;
}
