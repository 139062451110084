.agency{
	.section-1{
		z-index: 1;
		position: relative;
	}
	.home{
		@media(min-height:1100px){
			min-height:1100px;
		}
	}
	.hero{
		// background: url(../images/hero-gradient-2.jpg) no-repeat top;
		background-color: transparent;
		background-size: cover;
		min-height: 43em;
		position: relative;
		overflow: visible;
		@media(min-height:1100px){
			min-height:1100px;
		}
		.img-hero{
			width: 100%;
			top: auto;
			left: -60em;
			bottom: -40em;
			// left: -3em;
			// bottom: -8em;
			// transform:translate(-60em 40em);
			position: absolute;
			height: 100vh;
			overflow: visible;
			transition: all .8s ease-in-out;
			opacity: 0;
			&.rendered{
				opacity: 1;
			}
			@media(min-height:1100px){
				height:1100px;
			}
			.floating{
				height: 100%;
				width: 100%;
				&.floating-animation{
					transform: translatey(0px);
					animation: float 4s ease-in-out infinite;
					-webkit-animation: float 4s ease-in-out infinite;
					-moz-animation: float 4s ease-in-out infinite;
					-o-animation: float 4s ease-in-out infinite;
				}
			}
			@media (max-height: 900px) and (min-width: 1400px){
				width: 90%;
			}
			
			img{
				transition: all .3s ease;
				position: absolute;
				bottom: -10em;
				left: -12em;
				
				@media (min-width: 1400px) {
					max-width: 1650px;
				}
				@media (min-width: 1600px) {
					max-width: 1700px;
				}
				@media (max-width: 1399px) {
					max-width: 1600px;
				}
				&.psw1{
					z-index: 4;
					width: 110%;
					@media (max-width: 1399px) {
						width: 100%;
					}
					@media (min-width: 1600px) {
						width: 92%;
					}
				}
				&.psw2{
					z-index: 5;
					width: 150%;
					transform-origin: top;
					bottom: -15em;
					left: -20em;
					@media (min-width: 1600px) {
						max-width: 1850px;
					}
				}
			}
		}
	}
	.hero-title{
		&.hero-agency{
			overflow: visible;
		}
		.construct-desc{
			text-align: left;
			margin-left: 157px;
			h2{
				margin-left: -135px;
    			white-space: nowrap;
			}
		}
	}
	.help-productifity{
		h2{
			margin: 0;
			margin-bottom: 0;
			line-height: 1;
		}
		.list-productifity{
			display: flex;
			padding-top: 25px;
			.item{
				height: 489px;
				width: 300px;
				max-width:200px;
				// background: #f1f1f1;
				margin-right: 20px;
				&:last-child{
					margin-right: 0;
				}
				.tilter{
					width:100%;
					height:235px;
					border-radius: 4px;
					overflow:hidden;
					.tilter__deco--lines{
						top:6%;
						left:7%;
						width:86%;
						height:88%;
						border: 1px solid rgba(255, 255, 255, 0.25);
					}
					.tilter__deco--shine{
						opacity:.3;
					}
				}
				.box__inside{
					width: 100%;
					height: 235px;
					border-radius: 4px;
					padding: 15px;
					background: #fff;
					box-shadow: 0 7px 13px rgba(0, 6%, 29%, 0.19);
					margin-bottom: 15px;
					span{
						display: block;
						//border: 1px solid rgba(255, 255, 255, 0.25);
						width: 100%;
						height: 100%;
						color: #fff;
						padding: 15px;
						position: relative;
						.img__box{
							width: 100%;
							height: 100px;
							line-height: 100px;
    						text-align: center;
						}
					}
				}
				.text__box{
					font-size: 15px;
					font-family: $font-seravek;
					font-weight: $font-regular;
					position: absolute;
					bottom: 15px;
					left: 15px;
					right: 15px;
					// min-height: 63px;
					// text-align:center;
					padding:1em;
					max-width:172px;
				}
				&.pd1{
					padding-top: 15em;
					.box__inside{
						background: #c83399;
					}
				}
				&.pd2{
					padding-top: 12em;
					.box__inside{
						background: #4ebb46;
					}
				}
				&.pd3{
					padding-top: 8em;
					.box__inside{
						background: #f39033;
					}
				}
				&.pd4{
					padding-top: 4em;
					.box__inside{
						background: #af71d2;
					}
				}
				&.pd5{
					.box__inside{
						background: #6f2ea6;
					}
				}
			}
		}
	}
	.our-competency{
		.columns{
			display: flex;
			margin-bottom: 30px;
			.column{
				width: 50%;
				&.mrg-left75{
					margin-left: -75px;
				}
			}
		}
		.img_thums{
			width: 60px;
			height: 60px;
			border: 9px solid rgba(255,255,255,.2);
    		background: transparent;
			border-radius: 50%;
			position: relative;
			display: inline-block;
			vertical-align: top;
			&:before{
				content: '';
				width: 42px;
				height: 42px;
				background: $color-white;
				border-radius: 50%;
				display: block;
			}
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				max-width: 25px;
				display: block;
				transform: translate(-50%, -50%);
			}
		}
		.competen-desc{
			display: inline-block;
			vertical-align: top;
			padding-top: 10px;
			margin-left: 15px;
			h3{
				margin: 0;
				font-size: 21px;
				color: $color-white;
				font-weight: $font-medium;
				text-transform: uppercase;
				padding-bottom: 15px;
			}
			ul{
				padding: 0;
				margin: 0;
				list-style: none;
				display: inline-block;
				vertical-align: top;
				font-size: 14px;
				font-weight: $font-regular;
				margin-right: 30px;
				max-width: 150px;
				min-width: 150px;
				color: $color-white;
				li{
					margin-bottom: 0px;
				}
			}
		}
		.all-program{
			padding-top: 20px;
			ul{
				padding: 0;
				margin: 0;
				list-style: none;
				display: inline-block;
				vertical-align: top;
				li{
					display: inline-block;
					margin-right: 27px;
					margin-bottom: 20px;
					img{
						filter: invert(100%);
					}
				}
			}
		}
		.started{
			h2{
				margin: 0;
				line-height: 1;
			}
			p{
				font-size: 15px;
				color: $color-white;
				margin-left: 7px;
				margin-top: 5px;
			}
		}
	}
	.get-qotes{
		padding-bottom: 6em;
		padding-top: 6em;
		// height: 1900px;
		overflow: hidden;
		&.ffffiiiixeeed{
			position: fixed;
			top: 25px;
			left: 0;
			right: 0;
		}
		&.abso{
			min-height: 803px;
			position: absolute;
			top: auto;
			left: 0;
			right: 0;
			bottom: 0px;
		}
		.get-qotes-now{
			padding-bottom: 4em;
			float: right;
			width: 50%;
		}
		h2{
			margin: 0;
			line-height: 1;
			text-align: left;
		}
		.columns{
			display: flex;
			margin-bottom: 30px;
			.column{
				width: 50%;
			}
		}
		.save{
			width: 307px;
			height: 307px;
			background: transparent;
			border-radius: 50%;
			position: relative;
			font-size: 52px;
			font-weight: $font-medium;
			color: #2B2F36;
			border: 0;
			span{
				color: $color-white;
			}
			h3{
				margin: 10px 0 15px;
				line-height: 1;
				display: block;
				font-size: 21px;
				font-weight: $font-medium;
				color: $color-white;
				text-transform: uppercase;
				&.smaller{
					font-weight: $font-regular;
					font-size: 13px;
					white-space: nowrap;
					margin-top:15px;
				}
			}
			.sv_pulses{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				.sv_position{
					color: $color-white;
				}
				.pulse{
					left:-175px;
					bottom:-95px;
				}
			}
		}
		.sv_position_animation{
			width: 307px;
			height: 307px;
			border-radius: 50%;
			position: absolute;
			border: 0;
			background-color: rgba(255,255,255,.065);
			transform: scale(0);
		}
	}
	.descQuote{
		font-size: 52px;
		font-weight: $font-medium;
		color: $color-white;
		span{
			color: $color-white;
		}
		h3{
			margin: 10px 0 15px;
			line-height: 1;
			display: block;
			font-size: 21px;
			font-weight: $font-medium;
			color: $color-white;
			text-transform: uppercase;
		}
		p{
			font-size: 13px;
			color: $color-white;
			font-weight: $font-regular;
		}
	}
	.rates{
		padding-top: 0;
		p{
			font-size: 13px;
			color: $color-white;
			font-weight: $font-regular;
			margin-top:15px;
		}
	}
	.section-gradient{
		&.heightH{
			position: relative;
		}
	}
	// .section-gradient{
	// 	background: #5c76b7;
	// 	background: linear-gradient(195deg,#5c76b7,#39d4a9);
	// 	&.gd-green{
	// 		background: #8982e8;
	// 	    background: linear-gradient(90deg,#08e879,#8982e8)
	// 	}
	// }
	.have-questions{
		&.form-question{
			padding: 10em 0em;
		}
		.question{
			> .column{
				width: 55%;
				&:first-child{
					width: 35%;
				}
			}
		}
		.columns{
			display: flex;
			margin-bottom: 30px;
			.column{
				width: 50%;
			}
		}
		padding: 4em 0;
		h2{
			margin: 0;
		}
		.head{
			margin-bottom: 3em;
			h2{
				display: inline-block;
				margin-right: 5px;
			}
			p{
				color: #fff;
				margin-top: 5px;
				font-size: 15px;
				font-weight: $font-regular;
				margin-left: 5px;
			}
		}
		
		.list{
			// min-height: 130px;
			.number{
				width: 50px;
				height: 50px;
				background: #fff;
				border-radius: 50%;
				border: 4px solid #efefef;
				line-height: 35px;
				text-align: center;
				font-size: 15px;
				position: relative;
				color: #747474;
				font-weight: 400;
				padding: 2px;
				display: inline-block;
				vertical-align: top;
				span{
					// position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					border: 2px solid #FFA600;
					left: 0;
					top: 0;
					border-radius: 50%;
				}
			}
			.desc{
				display: inline-block;
				max-width: 80%;
				vertical-align: top;
				margin-left: 15px;
				padding-top: 8px;
				h4{
					color: #FFF;
					font-weight: 500;
					font-size: 16px;
					margin: 0;
					line-height: normal;
					margin-bottom: 12px;
				}
				p{
					font-size: 13px;
					color: #FFF;
					margin-bottom: 0;
				}
			}
		}
	}
	.match-form{
		.desc, .italic{
			color: #fff;
			// padding: 0 15px;
		}
		.columns{
			margin-bottom: 15px;
		}
		.field{
			width: 100%;
			padding-right: 20px;
			padding-left: 0;
		}
		.italic{
			font-style: italic;
		}
		.row{
			margin: 0;
		}
		.form-project{
			.row{
				.field{
					padding-left: 40px;
					background: #fff;
				}
				.col:last-child{
					padding-right: 0;
				}
			}
		}
	}
}

.question-listing{
	.item-list{
		background: $color-white;
		border-radius: 4px;
		padding: 20px;
		padding-bottom: 35px;
		max-width: 250px;
		min-height: 270px;
		display: inline-block;
		vertical-align: top;
		margin-right: 25px;
		margin-bottom: 28px;
		position: relative;
		box-shadow: 0px 4px 7px rgba(202, 202, 202, 0.28);
		h4.title{
			margin: 0;
			font-size: 15px;
			color: $color-dark;
			font-weight: $font-medium;
			border-bottom: 1px solid $color-light-grey;
			line-height: normal;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}
		p{
			line-height: 1.6;
			font-size: 13px;
			color: #b1b1b1;
		}
		.icon{
			width: 30px;
			height: 30px;
			border-radius: 50%;
			// background: #2e2e2e;
			position: absolute;
			bottom: 15px;
			text-align: center;
			&.ic_dialogue{
				background: #c83399;
				&:before{
					content:url(../images/icons/icon-faq-dialogue.svg);
					display: block;
					margin-top: 4px;
				}
			}
			&.ic_contract{
				background: #00bf2b;
				&:before{
					content:url(../images/icons/icon-faq-contract.svg);
					display: block;
					margin-top: 8px;
				}
			}
			&.ic_nda{
				background: #ff8800;
				&:before{
					content:url(../images/icons/icon-faq-nda.svg);
					display: block;
					margin-top: 6px;
					margin-left: 5px;
				}
			}
			&.ic_dedicated{
				background: #ba6ad8;
				&:before{
					content:url(../images/icons/icon-faq-dedicated.svg);
					display: block;
					margin-top: 4px;
				}
			}
			&.ic_payment{
				background: #7922ac;
				&:before{
					content:url(../images/icons/icon-faq-payment.svg);
					display: block;
					margin-top: 6px;
					margin-left: 2px;
				}
			}
			&.ic_communicate{
				background: #58a2ea;
				&:before{
					content:url(../images/icons/icon-faq-nda.svg);
					display: block;
					margin-top: 6px;
					margin-left: 5px;
				}
			}
			&.ic_leaf{
				background: #6b65f1;
				&:before{
					content:url(../images/icons/icon-faq-leaf.svg);
					display: block;
					margin-top: 6px;
				}
			}
		}
		&.full{
			max-width: 100%;
			min-height:180px;
		}
		&.no-bg{
			background: transparent;
			text-align: center;
			box-shadow: none;
			p{
				color: #2e2e2e;
				margin-top: 15px;
				a, a:hover, a:focus{
					color: #2e2e2e;	
					text-decoration: underline;
					font-weight: $font-regular;
				}
			}
		}
	}
}
.area-drag{
	width: 70%;
	background: #fff;
	height: 40px;
	padding: 9px;
	border-radius: 4px;
	padding-left: 40px;
	position: relative;
	float: left;
	cursor: pointer;
	.progress_circle{
		top: 11px;
		left: 10px;
	}
	&.progres_upload{
		&:before{
			display: none;
		}
	}
	p{
		margin-bottom: 0;
	}
	&:before{
		content: url(../images/icons/icon-link.svg);
		position: absolute;
		top: 5px;
		left: 10px;
	}
}
.button-dropbox{
	float: right;
	width: 28%;
	button{
		float: right;
		letter-spacing: 1px;
		background: #fff;
		color: #000;
		position: relative;
		padding-left: 40px;
		font-weight: $font-medium;
		text-transform: uppercase;
		font-size: 14px;
		&:before{
			content: url(../images/icons/icon-dropbox.svg);
			position: absolute;
			top: 6px;
			left: 10px;
		}
		&:hover, &:focus{
			color: #000;
		}
	}
}
#fileupload{
	display: none;
}

.append_files{
	margin-bottom: 25px;
	p{
		color: #fff;
		margin-bottom: 5px;
		position: relative;
		&:before{
			content: url(../images/icons/icon-check.svg);
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: 2.5px;
			margin-right: 10px;
		}
	}
}

.form-project.agency-form{
	padding: 10px 0;
	.row{
		.col{
			padding-right: 15px;
			.form-group{
				.phone{
					margin: 0;
				}
			}
		}
	}
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@-webkit-keyframes float{
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}
@-moz-keyframes{
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}
@-o-keyframes{
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}