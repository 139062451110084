// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
  // transition: all .35s ease-in-out; <-- ini buat apa ya? soalnya ini ngebuat hover enggak smooth
}



/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  transition: all .35s ease-in-out;
  &:hover, &:focus{
    outline: none;
    -webkit-tap-highlight-color:transparent;
  }

  @include on-event {
    color: $text-color;
  }

}
*[ng-click]{
  cursor: pointer;
}

*, button{
  &:hover, &:focus{
    outline: none;
    -webkit-tap-highlight-color:transparent;
  }
}


body{
	font-size: 14px;
	font-family: $text-font-stack;
  background: #f6f6f6;
}

.bg-gradient{
  background: #fff;
  background: -webkit-linear-gradient(#fff, #f2f2f2);
  background: -o-linear-gradient(#fff, #f2f2f2);
  background: -moz-linear-gradient(#fff, #f2f2f2);
  background: linear-gradient(#fff, #f2f2f2);
}
.bg-white{
  background: #fff;
}

.row{
    &:before, &:after{
        content:'';
        display: table;
        clear: both;
    }
    .col{
        float: left;
    }
}
.content-page{
  padding-top: 10px;
}
.text-normal{
  text-transform: none !important;
}
.min-section{
  min-height: 100vh;
  // @media(min-height: 900px){
  //   &:not(.story){
  //   max-height: 900px;
  //   min-height: auto;
  //   }
  //   &.story{
  //     width: 100%;
  //   }
  // }
}

.btn-minWidth{
  min-width: 95px;
}

.scrollmagic-pin-spacer{
  @media(min-height: 900px){
      .hero{
        .min-section{
          min-height: 100vh;
          max-height: 100vh;
        }
      }
  }
}
.hidden-overflow, .kodeku-page{
  overflow: hidden;
}
.arrow-wrapper{
  list-style: none;
  position: fixed;
  z-index: 8;
  top: 0;
  right: 2em;
  margin: 0;
  padding: 0;
  height: 100%;
  transition: all .6s ease;
  transform: translateY(0%);
  opacity: 1;
  width:1em;
  cursor: pointer;
  &.hided{
    transform: translateY(50%);
    opacity: 0;
    visibility: hidden;
  }
}
.arrow-pls{
  position:absolute;
  top:70%;
  height:50px;
  width:2px;
  background:$color-yellow;
  animation: scrolldown 2.8s cubic-bezier(.77,0,.175,1) infinite;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    right: -4.5px;
    width: 0;
    height: 0;
    border-top: 10px solid $color-yellow;
    border-right: 5px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
  }
}

@keyframes scrolldown {
  0%,100% {
      height:25px;
  }
  45% {
      height:80px;
  }
}
