.section-process {
  padding-bottom: 0;
  margin-bottom: -65px;
  z-index: 1;
  .section-heading{
    margin-top:0;
  }
  .phone-showcase {
    position:relative;
    margin-top:70px;
    .base,.feature{
      background-size:contain;
      background-repeat:no-repeat;
      height:580px;
    }
    .base{
      background-image: url(../images/howitworks/base.png);
    }
    .feature{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      transition: transform .3s ease-in-out;
      &.shadow{
        filter: drop-shadow(-17px 12px 10px rgba(0,0,0,0.07));
      }
    }
    $translate :
      translate(10px,-10px)
      translate(20px,-20px)
      translate(30px,-30px)
      translate(40px,-40px)
      translate(50px,-50px)
      translate(60px,-60px)
      translate(70px,-70px);

    @for $i from 1 through 7 {
        $x : ($i*10px);
        $y : ($i*10px);
        .feature-#{$i} {
            background-image: url(../images/howitworks/#{$i}.png);
            &.transition{
              transition-delay:(1500ms - ($i * 50));
            }
        }
        &.inview .feature-#{$i} {
          transform: nth($translate, $i);
        }
    }
  }
  .process-list {
    margin-top: 162px;
    padding: 0 80px;
    position:relative;
    .process-container{
      border: 1px solid rgba(255,255,255,0.2);
      border-width: 1px 1px 0 0;
      position:relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255,255,255,0.2)
      }
    }
    ul {
      padding: 20px 15px;
      list-style: none;
      margin: 0;
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        position: relative;
        .number {
          flex: 0 0 62px;
          display: block;
          margin-left: -45px;
          padding: 10px;
          border-radius: 50%;
          position:relative;
          transition: all .5s linear;
          .number-wrap {
            background: #fff;
            display: block;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            display:block;
            margin:0 auto;
            position: relative;
            transition: all .5s linear;
            .circle{
              visibility:hidden;
              opacity:0;
              position:absolute;
              top:0;
              bottom:0;
              left:0;
              right:0;
              transition: opacity .5s linear;
              svg{
                height: 35px;
                width: 36px;
                transform-origin: center center;
              }
            }
          }
          .bg-pulse{
            display: block;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            border-radius:50%;
            z-index:0;
          }
        }
        .content {
          padding: 10px 20px;
          color: #fff;
          h6 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .description {
          transition: opacity 1s ease-out, transform 1s ease-out;
          opacity: 0;
          height: 0;
          transform: translateY(30px);
          overflow: hidden;
          p {
            font-size: 15px;
          }
        }
        
        &:after{
          content: "";
          height: 45%;
          border-left: 1px solid rgba(255,255,255,0.2);
          position: absolute;
          top: 39px;
          left: -15px;
        }
        &:last-child{
          margin-bottom:0;
          &:after{
            height: 20%;
          }
        }
        &.active {
          &:not(:first-child){
            margin-top:25px;
          }
          .number {
            background: transparent;
            .number-wrap {
              height: 42px;
              width: 42px;
              line-height: 42px;
              font-size: 17px;
              .circle{
                visibility:visible;
                opacity: 1;
              }
            }

            .bg-pulse{
              background:rgba(255, 255, 255, 0.2);
              animation: pulses 1s ease-out infinite;
            }
          }
          .content {
            padding: 20px;
          }
          .description {
            opacity: 1;
            height: auto;
            margin-top: 10px;
            transform: translateY(0);
          }
          &:after{
            height: 65%;
            top: 75px;
          }
          &:last-child{
            &:after{
              height: 50%;
              .content {
                padding: 20px 20px 0;
              }
            }
          }
        }
      }
    }
    a.with-arrow{
      margin-bottom:0;
    }
    &:before,&:after{
      content: "";
      height: 15px;
      width: 1px;
      border-left: 1px solid rgba(255,255,255,0.2);
      position: absolute;
    }
    &:before{
      top:0;
    }
    &:after{
      bottom:0;
    }
  }
  .process-description {
    font-size: 14px;
    padding: 20px 50px;
    color: #fff;
    a {
      color: inherit;
    }
  }
  .development-wrapper {
    text-align: center;
    .feature-list {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;
      li {
        position: relative;
        display: inline-block;
        color: #ededed;
        font-size: 13px;
        margin-right: 20px;
        a {
          color: inherit;
          &:hover {
            color: #ff9500;
          }
        }
        &:not(:last-child):after {
          content: ">";
          position: absolute;
          top: -2px;
          right: -12px;
          color: #ff9500;
          font-size: 10px;
        }
        &.active {
          a {
            color: #ff9500;
            &:hover {
              color: #ededed;
            }
          }
        }
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
.radial-wrapper{
  position: relative;
  height:100vh;
  background-size: 100%;
  .radial-items{
    position:relative;
    display:block;
    width:100%;
    height:100vh;
    z-index:1;
    .radial-item{
      cursor:pointer;
      position: absolute;
      opacity: 0;
      transform:translateY(-50%);
      .short{
        display:flex;
        align-items: flex-start;
        .logo{
          position:relative;
          flex:0;
          padding:15px;
          margin-right:0;
          border-radius: 50%;
          .img-wrap{
            display: block;
            border-radius: 50%;
            text-align: center;
            height: 70px;
            width: 70px;
            line-height: 70px;
            transition: background .3s ease-in;
            z-index:1;
          }
          .bg-pulse{
            display: block;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            border-radius:50%;
            z-index:0;
          }
        }
        &:hover{
          .bg-pulse{
            background:rgba(255, 255, 255, 0.2);
            animation: pulses 1s infinite ease-out;
          }
          .logo{
            .img-wrap{
              background: #fff;
            }
          }
        }
      }
      .content{
        color:#fff;
        padding-top: 60px;
        .title{
          font-size:20px;
          font-weight:$font-medium;
        }
        .titlesub p{
          font-size:20px;
          font-weight:$font-regular;
          font-style:italic;
        }
        .price{
          p{
            padding-left:10px;
            margin:5px 0;
            font-weight: $font-x-light;
            font-size: 12px;
          }
          label{
            color:#fff;
            padding-left:40px;
          }
        }
      }
      &.active{
        .short{
          .logo{
            .bg-pulse{
              background:rgba(255, 255, 255, 0.2);
              animation: pulses 1s ease-out infinite;
            }
            .img-wrap{
              background: #fff;
            }
          }
        }
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5){
        .short{
          .logo{
            order:1;
            margin-right:0;
          }
          .content{
            text-align: right;
            margin-right:-10px;
            .price{
              p{
                padding-left:0;
                padding-right:10px;
              }
              label{
                padding-left:0;
                padding-right:40px;
              }
            }
          }
        }
      }
      &:nth-child(1){
        .short{
          .logo{ 
            img{
              height:50px;
            }
            .img-wrap{
              height:90px;
              width:90px;
              line-height:90px;
            }
          }
        }
        .content{
          padding-top: 60px;
          margin-top: 40px;
          margin-left: -45px;
        }
      }
      &:nth-child(2){
        transform: translate(-20%,0);
        .content{
          .title{
            font-size:16px;
          }
        }
      }
      &:nth-child(3){
        transform: translate(20%, -10%);
        .short{
          .logo{ 
            img{
              height:40px;
            }
            .img-wrap{
              height:80px;
              width:80px;
              line-height:80px;
            }
          }
          .content{
            margin-top: 15px;
            margin-right: -20px;
            .title, .titlesub p{
              font-size:18px;
            }
          }
        }
      }
      &:nth-child(4){
        .short{
          .logo{ 
            img{
              height:50px;
            }
            .img-wrap{
              height:90px;
              width:90px;
              line-height:90px;
            }
          }
          .content{
            margin-top: 25px;
            margin-right: -20px;
          }
        }
      }
      &:nth-child(5){
        transform: translate(20%,-100%);
        .short{
          .logo{ 
            img{
              height:30px;
            }
            .img-wrap{
              height:60px;
              width:60px;
              line-height:60px;
            }
          }
          .content{
            margin-top: 15px;
            margin-right: -20px;
            .title, .titlesub p{
              font-size:16px;
            }
          }
        }
      }
      &:nth-child(6){
        transform: translate(-20%,-100%);
        .content{
          .title, .titlesub p{
            font-size:16px;
          }
        }
      }
    }
  }
  .radial-description{
    position: absolute;
    top: 50%;
    left: 50%;
    width:31%;
    transform: translate(-50%,-50%);
    text-align: center;
    color:#fff;
    z-index:2;
    min-width: 420px;
    h2{
      color: #fff;
      font-weight: 500;
      font-size: 52px;
      margin-top:0;
      white-space: nowrap;
      .yellow-text-gradient{
        font-size:inherit;
        text-transform:none;
      }
    }
    h3{
      font-weight: 500;
      font-size: 32px;
      line-height: 29px;
      margin-bottom:13px;
      
      .underlined{
        border-bottom: thin solid $color-yellow;
      }
    }
    p{
      font-size:15px;
      margin-bottom: 35px;
      padding:0 25px;
    }
    .btn{
      display:inline-block;
      padding-left: 28px;
    }
  }
  .pulses{
    position:absolute;
    top:50%;
    left:20%;
    transform: translate(-50%, 0%);
    z-index:0;
    max-width:100%;
    text-align: center;
    .pulse{
      width:700px;
      height:700px;
      left:0;
      right:0;
      bottom:0;
      top:0;
      margin:auto;
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}
