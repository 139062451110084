.form-control{
	background: #fff;
	border-radius: 4px;
	padding: 13px 17px;
	font-size: 13px;
	border:none;
	font-family: $font-seravek;
	font-weight: $font-regular;
	display: block;
	width: 100%;
	color: #212121;
	height: 40px;
}

.field{
	margin-bottom: 10px;
	label{
		font-size: 15px;
		font-family: $font-seravek;
		font-weight: $font-medium;
		display: block;
		margin-bottom: 10px;
	}
}

.column{
	.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.field{
		float: left;
		width: 50%;
		padding: 0 15px;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #212121;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #212121;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #212121;
}
:-moz-placeholder { /* Firefox 18- */
  color: #212121;
}